import React from 'react';
import { motion } from 'framer-motion';
import Container from '../../common/container';

export default function AgentsSection() {
  const agents = [
    {
      title: "Customer Support",
      description: "Automate responses to common questions, freeing up your team to focus on complex issues.",
      icon: (
        <svg className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 10h.01M12 10h.01M16 10h.01M9 16H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-5l-5 5v-5z" />
        </svg>
      )
    },
    {
      title: "Order Management",
      description: "Let agents track orders, update customers on delivery status, and handle returns seamlessly.",
      icon: (
        <svg className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-6 9l2 2 4-4" />
        </svg>
      )
    },
    {
      title: "Personalized Shopping",
      description: "Create agents that recommend products based on customer preferences, driving higher conversions.",
      icon: (
        <svg className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
        </svg>
      )
    },
    {
      title: "Payment Processing",
      description: "Enable agents to securely collect payments, send invoices, and confirm transactions within chat.",
      icon: (
        <svg className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
        </svg>
      )
    }
  ];

  return (
    <motion.section
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -20 }}
      transition={{ duration: 0.5 }}
      className="py-24 bg-gradient-to-b from-gray-50 to-white"
    >
      <Container>
        <motion.div 
          className="mx-auto max-w-2xl text-center mb-16"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.2 }}
        >
          <span className="inline-flex items-center px-3 py-1 text-sm font-medium text-primary bg-primary/10 rounded-full mb-6">
            Intelligent Agents
          </span>
          <h2 className="text-4xl font-bold tracking-tight mb-6">
            Build Intelligent Agents
            <span className="block text-primary mt-2">No Code Required</span>
          </h2>
          <p className="text-gray-600 text-lg">
            Empower your business with custom AI-powered agents tailored to your unique needs—without writing a single line of code.
          </p>
        </motion.div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-16">
          {agents.map((agent, index) => (
            <motion.div 
              key={index}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.3 + index * 0.1 }}
              className="relative group p-6 bg-white rounded-2xl border border-gray-100 hover:border-primary/20 transition-all duration-300 hover:shadow-lg hover:shadow-primary/5"
            >
              <div className="flex items-start gap-4">
                <div className="flex-shrink-0 w-12 h-12 rounded-lg bg-primary/5 group-hover:bg-primary/10 flex items-center justify-center text-primary">
                  {agent.icon}
                </div>
                <div>
                  <h3 className="text-xl font-semibold mb-2">{agent.title}</h3>
                  <p className="text-gray-600">{agent.description}</p>
                </div>
              </div>
            </motion.div>
          ))}
        </div>

        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.7 }}
          className="bg-gradient-to-br from-gray-50 to-white p-8 rounded-2xl border border-gray-100"
        >
          <blockquote className="text-center">
            <p className="text-lg text-gray-600 italic mb-4">
              "WymBee's no-code agent builder has been a game-changer for our business. We automated 80% of our customer queries and saw a 3x increase in sales—all without hiring a developer!"
            </p>
            <footer className="text-gray-500">
              The Golf Hotel
            </footer>
          </blockquote>
        </motion.div>

        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.8 }}
          className="mt-12 text-center"
        >
          <a 
            href="https://wymbee.com/account/signin/"
            className="inline-flex items-center justify-center px-8 py-4 text-base font-medium text-white bg-primary hover:bg-primary-hover rounded-lg transition-colors duration-200"
          >
            Start Building Your Agents Now
          </a>
        </motion.div>
      </Container>
    </motion.section>
  );
}